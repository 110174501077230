<messages>["../Configuration"]</messages>

<template>
  <table-edit-menu
    :cancel-text="$t ('general.button.cancel')"
    :save-text="$t ('general.button.save')"
    :title-text="$t ('registries.select.edit.use')"
    @focus="focus"
    @cancel="cancel"
    @save="emit($event, 'save')">
    <template #activator="{ on }">
      <div
        class="primary--text"
        v-on="on"
        v-text="$t (`registries.use.${use}`)"/>
    </template>
    <v-autocomplete
      ref="input"
      v-model="internalUse"
      :items="useItems"/>
  </table-edit-menu>
</template>

<script>
  import TableEditMenu from './TableEditMenu'

  export default {
    components: {
      TableEditMenu
    },

    props: {
      use: {
        type: Number,
        default: 0
      },
      useItems: {
        type: Array,
        default: () => ([])
      }
    },

    data () {
      return {
        internalUse: ''
      }
    },

    watch: {
      use () {
        this.internalUse = this.use
      }
    },
    created () {
      this.internalUse = this.use
    },

    methods: {
      emit (e, name) {
        this.$emit (name, this.internalUse)
      },
      cancel () {
        this.internalUse = this.use
      },
      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>
