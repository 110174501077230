<messages>["./Configuration"]</messages>

<!--
================================================================================
  Template
================================================================================
-->

<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-alert
        id="metaDataError"
        type="error"
        dismissible
        :value="showErrorAlert">
        {{ $t ('registries.metadata.error', { config: erroneousConfig }) }}
      </v-alert>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <v-row justify="space-between">
            <v-col cols="12" sm="6">
              <div
                v-t="'registries.select.title'"
                class="text-h5"/>
              <div
                v-t="'registries.select.subTitle'"
                class="cgwng-subheading"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col sm="12" md="6">
                  <v-select
                    v-model="useFilter"
                    :label="$t ('registries.select.use')"
                    :items="useItemsWithNull"
                    @input="filterList"/>
                </v-col>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model.trim="search"
                    append-icon="search"
                    :label="$t ('general.label.search')"
                    single-line
                    hide-details
                    clearable
                    @input="inputSearch(false)"
                    @keyup.enter="inputSearch (true)"
                    @keyup.esc="search = ''; inputSearch (true)"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            hide-default-footer
            class="elevation-1"
            item-key="id"
            :no-data-text="noDataText (isLoading)"
            :no-results-text="noResultsText (isLoading)"
            :headers="headers"
            :items="filteredConfig"
            :items-per-page="-1"
            :loading="isLoading">
            <template #item="props">
              <tr
                v-if="!isConfigSelected || props.item.selected ||
                  isTableExpanded"
                class="pointer">
                <td
                  class="text-center"
                  @click="loadDetails (props.item)">
                  <v-icon>
                    {{ `radio_button_${props.item.selected ? '' : 'un'}checked`
                    }}
                  </v-icon>
                </td>
                <td
                  @click="loadDetails (props.item)"
                  v-text="props.item.name"/>
                <td>
                  <v-row justify="space-between">
                    <v-col>
                      <use-edit-menu
                        :use="props.item.use"
                        :use-items="useItems"
                        @save="saveUse($event, props.item)"/>
                    </v-col>
                    <v-col class="shrink">
                      <v-icon :color="getIcon(props.item.use).color">
                        {{ getIcon(props.item.use).icon }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </td>
                <td
                  @click="loadDetails (props.item)"
                  v-text="props.item.type"/>
                <td>
                  <comment-edit-menu
                    :comment="props.item.comment"
                    @save="saveComment ($event, props.item)"/>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        small
                        fab
                        text
                        color="error"
                        :disabled="!props.item.deletable"
                        v-on="on"
                        @click="setDeleteObject (props.item)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </template>
                    {{ props.item.deletable ? $t ('registries.deletable') : $t ('registries.notDeletable') }}
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template
              v-if="isConfigSelected"
              #footer>
              <v-divider/>
              <v-row dense justify="center" align-content="center" align="center">
                <v-col cols="1" class="d-flex">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="onToggleTableExpansion">
                        <v-icon>
                          {{ isTableExpanded ? 'expand_less' : 'expand_more' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t (isTableExpanded ?
                        'registries.select.collapse' :
                        'registries.select.expand') }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- selected config -->
    <template v-if="selectedConfig.name">
      <v-col cols="12" md="6">
        <cert-stores
          type="key"
          :show-actions="false"
          :stores="selectedConfig.keyStores"/>
      </v-col>
      <v-col cols="12" md="6">
        <cert-stores
          type="trust"
          :show-actions="false"
          :stores="selectedConfig.trustStores"/>
      </v-col>
      <v-col cols="12">
        <v-alert
          id="error"
          transition="slide-y-transition"
          type="error"
          :value="error">
          <pre class="inlineCode">
          {{ errorMsg }}
        </pre>
        </v-alert>
        <v-card>
          <v-card-title primary-title>
            <div
              class="text-h5"
              v-text="$t ('registries.details.title')"/>
          </v-card-title>
          <v-card-text>
            <xml
              v-model="selectedConfig.details"
              close-tags/>
          </v-card-text>
          <v-card-actions>
            <floating-button
              color="primary"
              :l-offset="2"
              :b-offset="2"
              :loading="isLoadingSave"
              @click="onSaveDetails">
              {{ $t ('general.button.save') }}
            </floating-button>
            <v-spacer/>
            <v-btn
              v-t="'general.button.reset'"
              text
              @click="onReset"/>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
    <v-col v-else-if="isConfigLoading">
      <v-progress-linear indeterminate/>
    </v-col>

    <confirmation-dialog
      v-model="showErrorDialog"
      :headline="$t ('registries.error.title')"
      @ok="scrollToAlert ('#error')">
      <span v-t="'registries.error.text'"/>
    </confirmation-dialog>

    <confirmation-dialog
      v-model="deleteDialog"
      is-delete
      :is-loading="deleteLoading"
      :headline="$t ('registries.delete.title', deleteObject)"
      :cancel-action="cancel"
      @ok="deleteCurrentObj">
      <span>{{ $t ('registries.delete.text', deleteObject) }}</span>
    </confirmation-dialog>
  </base-layout>
</template>

<!--
================================================================================
  Logic
================================================================================
-->

<script>
  import CertStores from './components/CertificateStores'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import FloatingButton from '@/app/core/components/FloatingButton'
  import Xml from '@/app/core/components/CodeEditor/Xml'

  import ContentHeightReporter from '@/app/core/mixins/ContentHeightReporter'
  import {noDataText, noResultsText} from '@/app/core/mixins/PaginationComponent'

  import {mapMutations, mapActions} from 'vuex'
  import _debounce from 'lodash/debounce'
  import goTo from 'vuetify/lib/services/goto'

  import CommentEditMenu from './components/CommentEditMenu'
  import UseEditMenu from './components/UseEditMenu'

  import errorTranslator from '@/app/services/errortranslator'

  function getEmptySelectedConfig () {
    return {
      id: '',
      name: '',
      details: '',
      keyStores: [],
      trustStores: []
    }
  }

  export default {
    name: 'Registries',

    components: {
      BaseLayout,
      CertStores,
      ConfirmationDialog,
      FloatingButton,
      Xml,
      CommentEditMenu,
      UseEditMenu
    },

    mixins: [ContentHeightReporter],

    data () {
      return {
        search: '',
        useFilter: -1,
        isLoading: true,
        isLoadingSave: false,
        isConfigLoading: false,
        configs: [],
        filteredConfig: [],
        isConfigSelected: false,
        selectedConfig: getEmptySelectedConfig (),
        isTableExpanded: false,
        originalValue: undefined,
        originalConfigDetails: '',
        originalUse: '',
        originalComment: '',
        error: false,
        showErrorDialog: false,
        showErrorAlert: false,
        erroneousConfig: '',
        errorMsg: '',
        deleteObject: null,
        deleteDialog: false,
        deleteLoading: false
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('registries.select.details'),
            value: 'details',
            sortable: false,
            align: 'center'
          },
          {
            text: this.$t ('registries.select.name'),
            value: 'name'
          },
          {
            text: this.$t ('registries.select.use'),
            value: 'use'
          },
          {
            text: this.$t ('registries.select.type'),
            value: 'type'
          },
          {
            text: this.$t ('registries.select.comment'),
            value: 'comment'
          },
          {
            text: this.$t ('registries.select.action'),
            sortable: false,
            value: 'action'
          }
        ]
      },

      useItems () {
        return [0, 1, 2, 3, 4].map (
          i => ({value: i, text: this.$t (`registries.use.${i}`)}))
      },

      useItemsWithNull () {
        return [-1, 0, 1, 2, 3, 4].map (
          i => ({value: i, text: this.$t (`registries.use.${i}`)}))
      }
    },

    watch: {
      error (newValue) {
        if (newValue) this.showErrorDialog = true
      },
      configs: {
        handler () {
          this.filterList ()
        },
        immediate: true
      }
    },

    mounted () {
      this.loadConfigs ()
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData',
        clearCache: 'cache/clearRegistryTypeDataCache'
      }),

      // default text properties, which can be used for `VDataTable` component
      noDataText,
      noResultsText,

      inputSearch (immediate) {
        const d = _debounce (() => this.filterList (), 800)
        d ()
        if (immediate || !this.search || this.search === '') d.flush ()
      },

      filterList () {
        const searchNormalized = this.search ? this.search.trim ().toLocaleLowerCase () : ''
        this.filteredConfig = this.configs.filter (
          (c) => (c.name.toLocaleLowerCase ().includes (searchNormalized) &&
            (this.useFilter === -1 || c.use === this.useFilter)) || c.selected
        )
      },

      getIcon (use) {
        const ERROR = 'error'
        const DISABLED = 'grey'
        const SUCCESS = 'success'
        const COMPONENT = 'widgets'
        const SERVICE = 'record_voice_over'

        const gen = (icon, color) => ({icon, color})

        switch (use) {
          case 0:
            return gen (COMPONENT, DISABLED)
          case 1:
            return gen (COMPONENT, SUCCESS)
          case 2:
            return gen (SERVICE, ERROR)
          case 3:
            return gen (SERVICE, SUCCESS)
          case 4:
            return gen (SERVICE, DISABLED)
          default:
            return gen ('', DISABLED)
        }
      },

      loadConfigs () {
        this.isLoading = true

        this.fetchData ({
          op: 'registry/config/list',
          cb: data => {
            this.configs = data.registryConfigurations.map (
              e => ({
                selected: false,
                ...e,
                newComment: e.comment,
                newUse: e.use
              }))
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      loadDetails (configuration) {
        this.error = false
        this.isConfigLoading = true

        this.fetchData ({
          op: 'registry/config/load',
          params: {
            id: configuration.id
          },
          cb: data => {
            this.selectedConfig = {
              id: configuration.id,
              name: configuration.name,
              details: data.registryConfiguration,
              keyStores: data.keyStores,
              trustStores: data.trustStores
            }

            this.originalConfigDetails = this.selectedConfig.details
          },
          cbFinal: () => {
            this.isConfigLoading = false
          }
        })

        this.selectedConfig = getEmptySelectedConfig ()

        this.configs.forEach (function (c) {
          c.selected = false
        })

        configuration.selected = true
        this.isConfigSelected = true
        this.isTableExpanded = false
      },

      onSaveDetails () {
        this.isLoadingSave = true
        this.error = false
        this.fetchData ({
          op: 'registry/config/update',
          params: {
            id: this.selectedConfig.id,
            content: this.selectedConfig.details
          },
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('registries.details.saved'))

            this.addEvent ({
              type: 'success',
              message: this.$t ('registries.details.saved'),
              objects: [{name: this.selectedConfig.name, link: {name: 'config.registries'}}]
            })

            this.error = false
            this.loadConfigs ()
            this.selectedConfig = getEmptySelectedConfig ()
            this.isConfigSelected = false
            this.isTableExpanded = false
            this.showErrorAlert = false
            this.clearCache ()
          },
          cbError: data => {
            this.error = true
            this.errorMsg = data.errorData || errorTranslator (data)
          },
          cbFinal: () => {
            this.isLoadingSave = false
          }
        })
      },

      onUseUpdate (newValue, oldValue, config) {
        if (newValue !== oldValue) {
          config.use = newValue
          this.onSaveMetaData (config, oldValue, config.comment)
          this.clearCache ()
        }
      },

      saveComment (e, item) {
        this.onCommentUpdate (e, item.comment, item)
      },

      saveUse (e, item) {
        this.onUseUpdate (e, item.use, item)
      },

      onCommentUpdate (newValue, oldValue, config) {
        if (newValue !== oldValue) {
          config.comment = newValue
          this.onSaveMetaData (config, config.use, oldValue)
        }
      },

      async onSaveMetaData (config, oldUse, oldComment) {
        return this.fetchData ({
          op: 'registry/config/update-meta',
          params: {
            id: config.id,
            use: config.use,
            comment: config.comment
          },
          cb: () => {
            this.showErrorAlert = false

            this.displaySuccessMessage (
              this.$t ('registries.details.saved'))

            this.clearCache ()
          },
          cbError: () => {
            config.use = oldUse
            config.comment = oldComment

            this.erroneousConfig = config.name
            this.showErrorAlert = true
            this.scrollToAlert ('#metaDataError')
          }
        })
      },

      onReset () {
        this.error = false
        this.selectedConfig.details = this.originalConfigDetails
      },

      onToggleTableExpansion () {
        this.isTableExpanded = !this.isTableExpanded
      },

      scrollToAlert (alertId) {
        goTo (alertId)
        this.showErrorDialog = false
      },

      setDeleteObject (obj) {
        this.deleteObject = {...obj}
        this.deleteDialog = true
      },

      deleteCurrentObj () {
        this.deleteLoading = true

        this.fetchData ({
          op: 'registry/config/delete',
          params: {
            id: this.deleteObject.id
          },
          cb: (data) => {
            const name = this.deleteObject.name
            this.deleteObject = null
            this.deleteDialog = false

            this.displaySuccessMessage (
              this.$t ('registries.delete.success'))

            this.addEvent ({
              type: 'success',
              message: this.$t ('registries.delete.success'),
              objects: [{name, link: {name: 'config.registries'}}]
            })

            this.isConfigSelected = false
            this.selectedConfig = getEmptySelectedConfig ()
            this.isTableExpanded = false
            this.error = false
            this.configs = data.registryConfigurations.map (
              e => ({
                selected: false,
                ...e,
                newComment: e.comment,
                newUse: e.use
              }))

            this.clearCache ()
          },
          cbFinal: () => {
            this.deleteLoading = false
          }
        })
      },

      cancel () {
        this.deleteDialog = false
        this.deleteObject = null
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
