<template>
  <v-menu
    v-model="menu"
    :close-on-click="true"
    :close-on-content-click="false">
    <template #activator="{ on }">
      <slot name="activator" :on="on"/>
    </template>

    <v-card>
      <v-card-text>
        <div
          class="mt-4 text-h6"
          v-text="titleText"/>
        <slot/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="close('cancel')">
          {{ cancelText }}
        </v-btn>
        <v-btn color="primary" @click="close('save')">
          {{ saveText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    props: {
      saveText: {
        type: String,
        default: 'Save'
      },
      cancelText: {
        type: String,
        default: 'Cancel'
      },
      titleText: {
        type: String,
        default: 'Edit'
      },
      comment: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        menu: false
      }
    },

    watch: {
      menu () {
        if (this.menu) {
          this.$nextTick (() => {
            this.$emit ('focus')
          })
        }
      }
    },
    methods: {
      close (event) {
        this.menu = false
        this.$emit (event)
      }
    }
  }
</script>
