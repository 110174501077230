<messages>["../Configuration"]</messages>

<template>
  <table-edit-menu
    :cancel-text="$t ('general.button.cancel')"
    :save-text="$t ('general.button.save')"
    :title-text="$t ('registries.select.edit.comment')"
    :comment="comment"
    @focus="focus"
    @cancel="cancel"
    @save="emit($event, 'save')">
    <template #activator="{ on }">
      <div v-on="on">
        <div
          v-if="comment"
          class="primary--text"
          v-text="comment"/>
        <v-btn
          v-else
          icon
          color="primary">
          <v-icon>mode_edit</v-icon>
        </v-btn>
      </div>
    </template>
    <v-textarea
      ref="input"
      v-model="internalComment"/>
  </table-edit-menu>
</template>

<script>
  import TableEditMenu from './TableEditMenu'

  export default {
    components: {
      TableEditMenu
    },

    props: {
      comment: {
        type: String,
        default: ''
      }
    },

    data () {
      return {
        internalComment: ''
      }
    },

    watch: {
      comment () {
        this.internalComment = this.comment
      }
    },
    created () {
      this.internalComment = this.comment
    },

    methods: {
      emit (e, name) {
        this.$emit (name, this.internalComment)
      },
      cancel () {
        this.internalComment = this.comment
      },
      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>
